<template>
  <section class="app-ecommerce-details">
    <b-col lg="12 px-0 mx-0" v-if="progress != 0">
      <b-card no-body class="card-employee-task">
        <b-card-header>
          <b-card-title>{{ $t("Pages.MasterFile.Title.downloading_wait") }}</b-card-title>
        </b-card-header>
        <div class="mx-2 pb-1">
          <b-progress animated :value="progress" variant="success" class="progress-bar-success" />
        </div>
      </b-card>
    </b-col>
    <!-- Content -->
    <b-card no-body>
      <b-card-body class="position-relative">
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="align-items-center justify-content-top mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="product.preview"
                :alt="`Image of ${product.title}`"
                class="product-img"
                fluid
              />
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4>{{ product.title }}</h4>

            <!-- Product Brand -->
            <b-card-text class="item-company mb-0" v-show="product.category_name">
              {{ this.$t(`Pages.MasterFile.Title.${product.category_name}`) }}
            </b-card-text>


            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1 d-flex justify-content-center align-items-center" v-if="(product.count_coin != 0)">
                <h2 class="user-name font-weight-bolder mb-0" style="padding-left: 2px ; padding-right: 2px;">{{product.count_coin}}*</h2>
                <b-img class="" :src="require('@/assets/images/icons/mastercoin_icon.png')" width="32"  />
              </h4>
              <h4 class="item-price mr-1 d-flex justify-content-center align-items-center" v-else>
                {{ $t("Pages.MasterFile.Title.Free") }}
              </h4>
            </div>

            <!-- Product Description -->
            <b-card-text
              class="pre-formatted info"
              v-html="product.desc"
            ></b-card-text>
            <hr />
            <div class="d-flex flex-column flex-sm-row pt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="handleCartActionClick(product)"
              >
                <feather-icon icon="DownloadIcon" class="mr-50" />
                <span>{{ $t("Pages.MasterFile.Button.Download") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>

      <div class="item-features">
        <div class="row text-center">
          <div class="col-12 col-md-4 mb-4 mb-md-0">
            <div class="w-75 mx-auto">
              <feather-icon icon="AwardIcon" size="35" />
              <h4 class="mt-2 mb-1">
                {{ $t("Pages.MasterCoin.Title.shopSupport_title1") }}
              </h4>
              <p class="card-text">
                {{ $t("Pages.MasterCoin.Title.shopSupport_text1") }}
              </p>
            </div>
          </div>
          <div class="col-12 col-md-4 mb-4 mb-md-0">
            <div class="w-75 mx-auto">
              <feather-icon icon="ClockIcon" size="35" />
              <h4 class="mt-2 mb-1">
                {{ $t("Pages.MasterCoin.Title.shopSupport_title2") }}
              </h4>
              <p class="card-text">
                {{ $t("Pages.MasterCoin.Title.shopSupport_text2") }}
              </p>
            </div>
          </div>
          <div class="col-12 col-md-4 mb-4 mb-md-0">
            <div class="w-75 mx-auto">
              <feather-icon icon="ShieldIcon" size="35" />
              <h4 class="mt-2 mb-1">
                {{ $t("Pages.MasterCoin.Title.shopSupport_title3") }}
              </h4>
              <p class="card-text">
                {{ $t("Pages.MasterCoin.Title.shopSupport_text3") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <b-card-body>
        <div class="mt-4 mb-2 text-center">
          <h4>{{ $t("Pages.MasterCoin.Title.related_products") }}</h4>
        </div>

        <!-- Swiper -->
        <swiper
          v-if="showProduct"
          class="swiper-responsive-breakpoints px-4 py-2"
          :options="swiperOptions"
        >
          <swiper-slide
            v-for="(product, index) in relatedProducts"
            :key="index"
          >
            <b-link
              :to="{ name: 'masterFileInfo', query: { id: product.id } }"
            >
              <div class="item-heading text-center pt-1">
                <h5 class="text-truncate mb-0">
                  {{ product.title }}
                </h5>
              </div>
              <div class="img-container w-80 mx-auto py-75">
                <b-img :src="product.preview" fluid />
              </div>
              <div class="item-meta">
                <h3
                  class="text-primary mb-0 card-text "
                  v-if="product.count_coin != 0"
                >
                  {{product.count_coin}}<span class="">*</span>
                  <b-img class="" :src="require('@/assets/images/icons/mastercoin_icon.png')" width="25"  />
                </h3>
                <p class="card-text text-primary mb-0" v-else>
                  {{ $t("Pages.MasterFile.Title.Free") }}
                </p>
              </div>
            </b-link>
          </swiper-slide>

          <!-- Add Arrows -->
        
            <feather-icon
            slot="button-next"
          :icon="language==='en'?'ChevronRightIcon':'ChevronLeftIcon'"
          class="swiper-button-next text-primary"
        />
        <feather-icon
        slot="button-prev"
          :icon="language==='en'?'ChevronLeftIcon':'ChevronRightIcon'"
          class="swiper-button-prev text-primary"
        />
    
        </swiper>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BModal,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BBadge,
  BProgress
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    Swiper,
    SwiperSlide,
    BModal,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BBadge,
    BProgress
  },
  data() {
    return {
      product: {},
      showProduct: false,
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 55,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1600: {
            slidesPerView: 4,
            spaceBetween: 55,
          },
          1300: {
            slidesPerView: 3,
            spaceBetween: 55,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 55,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 55,
          },
        },
      },
      relatedProducts: [],
      price_discount: null,
      coupon: "",
      PermissionDownlaod:false,
      NotificationStartDownlaod: false,
      progress: 0,
      count_coin:0,
    };
  },
  mounted() {
    this.getCountCoin()
    this.getProduct();
  },
  computed:{
    language(){
      return this.$i18n.locale
    }
  },
  watch: {
    $route(to, from) {
      if (to.query != from.query) {
        this.getProduct();
      }
    },
  },
  methods: {
    getProduct() {
      this.$http
        .get(`/api/master-file/item/info?id=${this.$route.query.id}`)
        .then((response) => {
          this.showProduct = true;
          this.product = response.data.file;
          this.relatedProducts = response.data.related_file;
        })
        .catch((error) => {
          this.showProduct = true;
        });
    },
    handleCartActionClick(product) {
      this.$http.get("/api/user/setting/check-verify").then((response) => {
      if (response.data && response.data.status) {
          if (this.progress == 0) {
            if (this.count_coin > product.count_coin) {
              this.$http.post(`/api/master-file/item/download`, { id: product.id }).then((response) => {
                if (response.status == 200) {
                  this.$http({
                    url: response.data,
                    method: 'GET',
                    responseType: 'blob',
                    onDownloadProgress: (progressEvent) => {
                      if (this.progress == 0 && !this.NotificationStartDownlaod) {
                        window.scrollTo(0, 0);
                        this.makeToast(
                          this.$t("Base.Alert.Successful"),
                          this.$t("Base.Alert.downloading_wait"),
                          "success",
                          10000
                        );
                        this.NotificationStartDownlaod = true;
                      }
                      this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total); // you can use this to show user percentage of file downloaded
                    }
                  }).then((res) => {
                    if (res.status == 200) {
                      if (res.data) {
                        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                        var fURL = document.createElement('a');
                        fURL.href = fileURL;
                        fURL.setAttribute('download', product.name_file);
                        document.body.appendChild(fURL);
                        fURL.click();
                        this.makeToast(
                          this.$t("Base.Alert.Successful"),
                          this.$t("Base.Alert.download_success"),
                          "success"
                        );
                        this.progress = 0;
                        this.NotificationStartDownlaod = false;
                        this.$root.$emit("updateCoin", true);
                      }
                    } else {
                      this.progress = 0;
                      this.NotificationStartDownlaod = false;
                      this.makeToast(
                        this.$t("Base.Alert.Error"),
                        this.$t("Base.Alert.verify_email_phone"),
                        "danger"
                      );
                    }
                  });
                }
              })
            } else {
              this.makeToast(
                this.$t("Base.Alert.Error"),
                this.$t("Base.Alert.not_enough_coin"),
                "danger"
              );
              this.showModal();
            }
          } else {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Base.Alert.wait_until_download"),
              "danger"
            );
          }
      }else{
        this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Base.Alert.verify_email_phone"),
            "danger"
          );
      }
    })
    },
    getCountCoin() {
      this.$http
        .get(`/api/dashboard/getCountCoin`)
        .then((response) => {
          this.count_coin = response.data.count_coin;
        });
    },
    showModal() {
      this.$swal({
        title: this.$t("Base.Alert.Error"),
        text: this.$t("Base.Alert.not_enough_coin"),
        icon: 'warning',
        confirmButtonText: this.$t("Pages.MasterFile.Title.Buy"),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$router.push({name:'MasterCoin'}).catch(() => { });
        }
      })
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import "@core/scss/vue/libs/swiper.scss";
@import "~swiper/css/swiper.css";

.info img {
  width: 100%;
  height: auto;
}

.info iframe {
  width: 100%;
  min-height: 400px;
}

@media screen and (max-width: 540px) {
  .info iframe {
    width: 100%;
    height: auto;
    min-height: 0px;
  }
}
</style>
